// Stimulus controller for basic offline filtering
// Do not use for more than 1000 elements, that could break the page for old devices.

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'medicationRow', 'yesButton', 'noButton' ]
  static values = { initialTaking: Boolean }

  connect(){
    this._updateButtonClasses(this.initialTakingValue)
  }

  updateTaking(event){
    event.preventDefault()
    let measurementId = this.medicationRowTarget.dataset.measurementId
    var isTaking = (event.target.innerText === "Yes")

    this._updateUserMeasurementMedication(measurementId, isTaking)
    this._updateButtonClasses(isTaking)
  }

  _updateUserMeasurementMedication(measurementId, isTaking){
    let authenticity_token = document.querySelector('head meta[name="csrf-token"]').content

    fetch('/profile/medication/' + measurementId, {
      method: 'PATCH',
      body: JSON.stringify( { measurements_medication: {currently_taking: isTaking} }),
      dataType: 'script',
      headers: { "X-CSRF-Token": authenticity_token, "Content-Type": "application/json" }
    })
  }

  _updateButtonClasses(isTaking) {
    if (isTaking == true){
      this.yesButtonTarget.classList.remove('btn-light')
      this.noButtonTarget.classList.add('btn-light')
    }else if (isTaking == false){
      this.noButtonTarget.classList.remove('btn-light')
      this.yesButtonTarget.classList.add('btn-light')
    }
  }
}
