// Stimulus controller for basic offline filtering
// Do not use for more than 1000 elements, that could break the page for old devices.

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'locale' ]

  connect(){
    let params = new URLSearchParams(location.search)
    let locale = params.get('locale')
    this.localeTargets.forEach((el, i) => {
      if(locale !== null && el.dataset.locale == locale && el.dataset.userLocale == "") {
        el.classList.add('active')
      }else if(el.dataset.userLocale == el.dataset.locale){
        el.classList.add('active')
      }else if(locale === null && el.dataset.userLocale == ''){
        el.dataset.locale == 'en' && el.classList.add('active')
      }
    })
  }

  visitWithLocale(event){
    event.preventDefault()
    let locale = event.target.dataset.locale
    let url = location.origin + location.pathname
    let user_locale = event.target.dataset.userLocale

    if (user_locale == "") {
      url += this._paramsWithLocale(locale, location.search)
    } else {
      url += this._paramsWithLocale(locale, location.search)
      this._updateUserLocaleSetting(locale)
    }

    if (typeof Turbo != 'undefined') {
      Turbo.visit(url)
    } else {
      location.href = url
    }
  }

  _updateUserLocaleSetting(locale){
    let authenticity_token = document.querySelector('head meta[name="csrf-token"]').content

    fetch('/user_setting/', {
      method: 'PATCH',
      body: JSON.stringify( { user_setting: {locale: locale} }),
      dataType: 'script',
      headers: { "X-CSRF-Token": authenticity_token, "Content-Type": "application/json" }
    })
  }

  _paramsWithLocale(locale, currentParams) {
    let params = new URLSearchParams(currentParams)
    params.set('locale',locale)
    return '?' + params.toString()
  }
}
