// Stimulus controller for basic offline filtering
// Do not use for more than 1000 elements, that could break the page for old devices.

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'source', 'filterable', 'results', 'notfound' ]
  static values = { showing: String, results: String }

  filter(event) {
    let filterText = this.sourceTarget.value.toLowerCase()

    this.filterableTargets.forEach((el, i) => {
      el.classList.toggle('d-none', !el.dataset.filterKey.includes( filterText ) )
    })
    this._updateResults()
  }

  _updateResults(){
    let count = this.filterableTargets.reduce((acc, cur) => cur.classList.contains('d-none') ? acc : ++acc, 0);
    this.resultsTarget.textContent = `${this.showingValue} ${count} ${this.resultsValue}`
    if (count == 0 && this.notfoundTarget.classList.contains('d-none')) {
      this.notfoundTarget.classList.remove('d-none')
    } else if (count > 0 && !this.notfoundTarget.classList.contains('d-none')) {
      this.notfoundTarget.classList.add('d-none')
    }
  }
}
