import { Controller } from "stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Tippy.js docs: https://atomiks.github.io/tippyjs/v6/getting-started/
export default class extends Controller {
  static values = { content: String, placement: String }
  connect() {
    tippy(this.element, {
      content: this.contentValue,
      placement: this.placementValue || 'top',
      allowHTML: true,
    });
  }
}
