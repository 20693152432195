import { Controller } from 'stimulus'

export default class extends Controller {
  static targets  = ['agreementSection', 'deviceSection', 'instructionsSection', 'thanksSection',
    'deviceAlert', 'agreementAlert', 'instructionsAlert', 'thanksAlert']

    showNextStep(event){
    event.preventDefault()
    let current_section = this.selectStep(event.target.dataset.currentStep)
    let next_section = this.selectStep(event.target.dataset.nextStep)

    current_section.classList.add('d-none')
    next_section.classList.remove('d-none')
  }

  selectStep(step){
    switch(step) {
      case 'agreement':
        return this.agreementSectionTarget
      case 'agreement-alert':
        return this.agreementAlertTarget
      case 'device':
        return this.deviceSectionTarget
      case 'device-alert':
        return this.deviceAlertTarget
      case 'instructions':
        return this.instructionsSectionTarget
      case 'instructions-alert':
        return this.instructionsAlertTarget
      case 'thanks':
        return this.thanksSectionTarget
      case 'thanks-alert':
        return this.thanksAlertTarget
    }
  }
}
